"use client";
import Link from "next/link";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { childVariants } from "../constants/motion";
import Image from "next/image";

const defaultIcon = "/images/icons/quickedit.svg";

// Define an array of Tailwind CSS background color classes
const carouselColors = [
  "bg-blue-200",
  "bg-neutral-400",
  "bg-gray-300",
  "bg-green-300",
  "bg-slate-300",
  "bg-stone-400",
  "bg-red-300",
  "bg-orange-300",
  "bg-lime-200",
  // Add more colors as needed
];

// Props: h2_text, description, carouselItems
const ServicesCarousel = ({ h2_text, description, carouselItems }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-color-brown-800 pb-10 md:pb-0 md:mb-0">
      <div className="max-w-screen-xl mx-5 lg:mx-auto px-2 md:px-8 lg:py-10">
        <div className="flex flex-col md:flex-row gap-3 md:gap-8 items-center justify-start mb-2 pb-10">
          <div className="flex-1 basis-2/5">
            <h2 className="mt-10 lg:mt-0 font-medium text-color-orange-400">
              {h2_text}
            </h2>
          </div>
          <div className="flex-1 basis-3/5 text-white self-center">
            <p>{description}</p>
          </div>
        </div>
        <div className="flex-1">
          <Slider {...settings}>
            {carouselItems.map((item, index) => (
              <div key={index} className="w-full lg:basis-1/4">
                <div
                  className={`${
                    carouselColors[index % carouselColors.length]
                  } basis-1/4 px-10 py-10 md:py-10 text-color-brown-900 h-full flex flex-col items-left justify-start min-h-[400px]`}
                >
                  {item.image && (
                    <Image
                      src={item.image || defaultIcon}
                      className="object-cover my-4 md:my-6"
                      width={65}
                      height={65}
                      alt={item.headline}
                    />
                  )}
                  <h3 className="text-2xl font-semibold">
                    <Link
                      href={item.link}
                      className="hover:text-color-orange-500"
                    >
                      {item.headline}
                    </Link>
                  </h3>
                  <div variants={childVariants} className="text-md">
                    {item.caption}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ServicesCarousel;
